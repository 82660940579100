<template>
  <v-dialog
    style="z-index: 1000"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    v-model="editDialog"
  >
    <v-card>
      <v-card-title class="d-flex">
        <span class="flex-grow-1"
          >{{ $vuetify.lang.t("$vuetify.worksheet.worksheet") }}
          {{ $vuetify.lang.t("$vuetify.base.notes") }}</span
        >
        <v-btn icon @click="cancel()"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-textarea
          :placeholder="$vuetify.lang.t('$vuetify.worksheet.noteInstruction')"
          class="my-4"
          outlined
          v-model="notes"
          hide-details
        />
        <v-row v-if="images && images.length > 0">
          <v-col
            v-for=" (img, index) in images"
            cols="1"
            md="2"
            lg="3"
            :key="index"
          >
            <v-img height="100px" contain aspect-ratio="1.7" :src="img.src">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div class="text-center text-caption ma-2">{{img.time | formatDateTime }}</div>
          </v-col>
        </v-row>

        <div class="d-flex justify-center">
          <image-upload
            class="mt-4 mx-2"
            v-model="files"
            :loading="loading"
            v-if="!submitted"
          />
        </div>
      </v-card-text>
      <v-card-actions class="justify-center py-6">
        <v-btn class="mx-2" @click="cancel()"
          >{{ $vuetify.lang.t("$vuetify.base.cancel") }}
        </v-btn>
        <v-btn class="mx-2" @click="saveNotes" color="primary">{{
          $vuetify.lang.t("$vuetify.base.submit")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
const ImageUpload = () => import("@/components/common/ImageUpload.vue");

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    worksheet: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => {
    return {
      notes: null,
      savedFiles: [],
      files: [],
      editDialog: false,
    };
  },
  components: {
    ImageUpload
  },
  watch: {
    editDialog(val){
      this.$emit('input', val);
    }
  },
  computed: {
    images(){
      if(this.savedFiles && this.savedFiles.length > 0){
        return this.savedFiles.map(file => {
              const src = `${process.env.VUE_APP_BASE_URL}/file/worksheet/${file.id}`
              return {src, id: file.id, time: file.createdTime}
          })
      }
      else{ return []}
    },
  },
  mounted() {
    this.notes = this.worksheet.notes;
    this.savedFiles = this.worksheet.files ? this.worksheet.files:[];
    this.editDialog = this.value;
  },
  methods: {
    ...mapActions("messages", ["addMessage", "addSuccessMessage", "addErrorMessage"]),
    ...mapActions("processWorksheet", ["updateProcessWorksheet"]),
    async saveNotes() {
      // console.log("saving notes: ", this.notes);
      this.worksheet.notes = this.notes;
      await this.updateProcessWorksheet(this.worksheet)
        .then(() => {
          this.$emit("update:worksheet", this.worksheet);
          this.addSuccessMessage("Worksheet notes saved");

          return true;
        })
        .catch((error) => {
          this.addErrorMessage(
            "Error saving worksheet notes; " + error.data.message
          );
          return false;
        });

      
      if (this.files && this.files.length > 0) {
        await this.uploadFiles(this.worksheet.id, this.files)
          .then(() => {
            this.editDialog = false;
            this.savedFiles = this.savedFiles.concat(this.files);
            this.$emit("input", this.editDialog);
          })
      }
      else{
        this.editDialog = false;
        this.$emit("input", this.editDialog);
      }

    },
    async uploadFiles(worksheetId, files) {
      if (files && files.length > 0) {
        var formData = new FormData();
        for (const f of files) {
          formData.append("files", f, f.name);
        }

        this.addMessage(this.$vuetify.lang.t("$vuetify.worksheet.uploading"));
        return await this.$axios
          .post(`/processWorksheet/upload/${worksheetId}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((resp) => {
            const savedFiles = resp.data;
            
            this.addSuccessMessage(this.$vuetify.lang.t("$vuetify.worksheet.uploadedXFiles", savedFiles.length));
          });
      }
    },
    cancel() {
      this.editDialog = false;
      this.$emit("input", this.editDialog);
    },
  },
};
</script>

<style></style>
